import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IUserModel } from './types';

export const apiGetUsers = async ({
  pageIndex,
  pageSize,
  sortBy,
  sortOrder,
  search = '',
  filter,
  scammer = false,
  archived = false,
  network,
}: {
  search?: string | null;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  filter?: any;
  scammer: boolean;
  archived: boolean;
  network: string;
}) => {
  try {
    const response = await axios.post<{
      total: number;
      data: IUserModel[];
    }>('/api/admin/v1/users', {
      page: pageIndex,
      limit: pageSize,
      ...(sortBy &&
        sortOrder && {
          sort: [{ field: sortBy, order: sortOrder }],
        }),
      ...(search && {
        search,
      }),
      filter,
      scammer: scammer,
      archived: archived,
      network:
        network === 'XDC' ? 'xdc' : network === 'ICP' ? 'nfid' : 'vechain',
    });

    return {
      rows: response.data.data,
      total: response.data.total,
    };
  } catch (e) {
    enqueueSnackbar('Failed to load users data', { variant: 'error' });
    return {
      total: 0,
      rows: [],
    };
  }
};

export const apiGetUser = async (id: string) => {
  try {
    const response = await axios.get(`/api/admin/v1/user/${id}`);
    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to get the selected users', {
      variant: 'error',
    });
    return false;
  }
};

export const apiUpdateUserStatus = async (id: string, status: boolean) => {
  try {
    await axios.put(`/api/admin/v1/user/${id}`, {
      disabled: status,
    });
    enqueueSnackbar('Successfully updated the selected user', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update the selected users', {
      variant: 'error',
    });
    return false;
  }
};

export const apiUpdateUserWhitelist = async (
  id: string,
  whitelist: boolean,
) => {
  try {
    await axios.put(`/api/admin/v1/user/${id}`, {
      whitelisted: whitelist,
    });
    enqueueSnackbar('Successfully updated the whitelist', {
      variant: 'success',
    });
  } catch (e) {
    enqueueSnackbar('Failed to update the whitelist', {
      variant: 'error',
    });
    return false;
  }
};

export const apiUpdateUserToScammer = async (id: string, status: boolean) => {
  try {
    await axios.put(`/api/admin/v1/user/${id}`, {
      scammed: status,
    });
    enqueueSnackbar('Successfully updated the user to scammer', {
      variant: 'success',
    });
  } catch (e) {
    enqueueSnackbar('Failed to update the user to scammer', {
      variant: 'error',
    });
    return false;
  }
};

export const apiUpdateCountLogin = async (id: string, value: number) => {
  try {
    await axios.put(`/api/admin/v1/user/${id}`, {
      count_login: value,
    });
  } catch (e) {
    enqueueSnackbar('Failed to update the user to count login', {
      variant: 'error',
    });
    return false;
  }
};

export const apiDeleteUser = async (id: string) => {
  try {
    await axios.delete(`/api/admin/v1/user/${id}`);
    enqueueSnackbar('Successfully deleted the selected user', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to delete the selected users', {
      variant: 'error',
    });
    return false;
  }
};

export const apiGetLessonsUser = async (id: string) => {
  try {
    const response = await axios.post(`/api/admin/v1/user/${id}/lessons`);
    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to get lesson the selected users', {
      variant: 'error',
    });
    return false;
  }
};

export const apiGetMappingUser = async (id: string) => {
  try {
    const response = await axios.post(`/api/admin/v1/user/${id}/places`);
    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to get mapping the selected users', {
      variant: 'error',
    });
    return false;
  }
};

export const apiGetTransactionUser = async (id: string) => {
  try {
    const response = await axios.post(`/api/admin/v1/user/${id}/transactions`);
    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to get transaction the selected users', {
      variant: 'error',
    });
    return false;
  }
};

export const apiGetLinkedScammers = async (id: string) => {
  try {
    const response = await axios.get(
      `/api/admin/v1/user/${id}/linked-scammers`,
    );
    return response.data || [];
  } catch (e) {
    enqueueSnackbar('Failed to get linked scammers', {
      variant: 'error',
    });
    return [];
  }
};

export const apiUpdateM2ELocked = async (
  id: string | undefined,
  value: string | null,
) => {
  try {
    await axios.put(`/api/admin/v1/user/${id}`, {
      m2e_locked: value,
    });
    enqueueSnackbar('Successfully updated the selected user', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update m2e locked the selected users', {
      variant: 'error',
    });
    return false;
  }
};
