import { Stack, Typography, Button, Box, IconButton } from '@mui/joy';
import { Calculator, Eye } from 'lucide-react';
import React, { FC, useMemo, useState, useEffect } from 'react';
import { IRoundModel, IRewardInfoModel } from '../../apis/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  PaginationState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DataTable from '../../components/datatable/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from '../../components/forms/CustomInput';
import {
  apiGetRounds,
  apiGetRewardInfo,
  apiUpdateRewardInfo,
} from '../../apis/raffle';
import RaffleRoundStatusChip from '../../components/chips/RaffleRoundStatusChip';
// import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';

const schema = yup.object({
  four_week_raffle_reward_amount: yup.number().required(),
  weekly_raffle_reward_amount: yup.number().required(),
});

const RafflePage: FC = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(params.get('pageIndex'))
      ? Number(params.get('pageIndex')) - 1
      : 0,
    pageSize: Number(params.get('pageSize')) || 10,
  });

  const { control, handleSubmit, setValue } = useForm<IRewardInfoModel>({
    defaultValues: {
      four_week_raffle_reward_amount: 0,
      weekly_raffle_reward_amount: 0,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.set('pageIndex', (pagination.pageIndex + 1).toString());
      newParams.set('pageSize', pagination.pageSize.toString());
      return newParams;
    });
  }, [pagination]);

  const { data, isLoading } = useQuery({
    queryKey: ['rounds'],
    queryFn: async () => {
      const data = await apiGetRounds({
        page: 1,
        limit: 10,
      });

      return {
        rows: data,
        total: data.length,
      };
    },
    placeholderData: keepPreviousData,
  });

  useQuery({
    queryKey: ['rewards'],
    queryFn: async () => {
      const data = await apiGetRewardInfo();

      setValue(
        'four_week_raffle_reward_amount',
        data.four_week_raffle_reward_amount,
      );
      setValue('weekly_raffle_reward_amount', data.weekly_raffle_reward_amount);
      return data;
    },
    placeholderData: keepPreviousData,
  });

  const onSubmit = async (values: IRewardInfoModel) => {
    if (values) {
      await apiUpdateRewardInfo(values);
    }
  };

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IRoundModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('round', {
        header: 'Round',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('type', {
        header: 'Type',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('total_voters', {
        header: 'Voters',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('total_votes', {
        header: 'Total votes',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('status', {
        header: 'Status',
        enableColumnFilter: false,
        cell: (info) => <RaffleRoundStatusChip status={info.getValue()} />,
      }),
      helper.accessor('wallet_address', {
        header: 'Winner Address',
        cell: (info) => info.getValue(),
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <IconButton
            size="sm"
            color="primary"
            onClick={() => {
              navigate(`voter/${info.row.original.round}`);
            }}
          >
            <Eye size={16} />
          </IconButton>
        ),
        size: 40,
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.rows ?? [],
    enableFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: data?.total ?? 0,
    state: {
      pagination: pagination,
    },
    onPaginationChange: setPagination,
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Calculator />
          <Typography level="h3">Raffle</Typography>
        </Stack>
        <Button type="submit">Update</Button>
      </Stack>
      <Stack direction="row" gap={4} mt={4}>
        <Stack>
          <Controller
            name="weekly_raffle_reward_amount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                title="WEEKLY DRAW REWARD"
                placeholder="Weekly draw reward"
                value={value}
                onChange={(e) => onChange(e)}
                required={true}
              />
            )}
          />
        </Stack>

        <Stack>
          <Controller
            name="four_week_raffle_reward_amount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                title="MONTHLY DRAW REWARD"
                placeholder="monthly draw reward"
                value={value}
                onChange={(e) => onChange(e)}
                required={true}
              />
            )}
          />
        </Stack>
      </Stack>

      <Stack mt={4}>
        <DataTable table={table} loading={isLoading} />
      </Stack>
    </Box>
  );
};

export default RafflePage;
