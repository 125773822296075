import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, IconButton, Input, Select, Option, Typography } from '@mui/joy';
import { Popover, Box, Stack } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { EditIcon, TrashIcon, Settings } from 'lucide-react';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import moment from 'moment';

import StatCard from '../../components/widgets/StatCard';
import DataTable from '../../components/datatable/DataTable';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import { getSortingStateFromQuery } from '../../components/functions';
import {
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from '../../components/tabs/Tab';
import {
  apiGetUsers,
  apiDeleteUser,
  apiUpdateM2ELocked,
  apiUpdateCountLogin,
} from '../../apis/users';
import { IUserModel } from '../../apis/types';
import { IRootState } from '../../redux/store';

countries.registerLocale(en);
const UsersPage: FC = () => {
  const user = useSelector((state: IRootState) => state.user);
  const { network } = useSelector((state: any) => state.network);
  const navigate = useNavigate();
  const [search, setSearch] = useState(user.search || '');
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [totalXp, setTotalXp] = useState<number>();
  const [l2e_xp, setL2exp] = useState<number>();
  const [m2e_xp, setM2exp] = useState<number>();
  const [referralXp, setReferralXp] = useState<number>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [flag, setFlag] = useState<boolean>(false);
  const [params, setParams] = useSearchParams();
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [scamFilters, setScamFilters] = useState<ColumnFiltersState>([]);
  const statusList = ['Active', 'Disabled'];

  const [modals, setModals] = useState<any>({
    remove: {
      open: false,
      userId: null,
    },
    login: {
      open: false,
      userId: null,
      count_login: 0,
    },
  });

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(params.get('pageIndex'))
      ? Number(params.get('pageIndex')) - 1
      : 0,
    pageSize: Number(params.get('pageSize')) || 10,
  });

  const [sorting, setSorting] = useState<SortingState>(
    getSortingStateFromQuery(params, [
      '_id',
      'username',
      'wallet_address',
      'telegram_id',
    ]),
  );

  useEffect(() => {
    const sortBy = params.get('sortBy');
    const sortingMap: { [key: string]: { id: string; desc: boolean } } = {
      'total_xp,desc': { id: 'total_xp', desc: true },
      'total_xp,asc': { id: 'total_xp', desc: false },
      'cur_xp,desc': { id: 'cur_xp', desc: true },
      'cur_xp,asc': { id: 'cur_xp', desc: false },
      'disabled,desc': { id: 'disabled', desc: true },
      'disabled,asc': { id: 'disabled', desc: false },
      'wallet_address,desc': { id: 'wallet_address', desc: true },
      'wallet_address,asc': { id: 'wallet_address', desc: false },
      'username,desc': { id: 'username', desc: true },
      'username,asc': { id: 'username', desc: false },
      '_id,desc': { id: '_id', desc: true },
      '_id,asc': { id: '_id', desc: false },
      'telegram_id,desc': { id: 'telegram_id', desc: true },
      'telegram_id,asc': { id: 'telegram_id', desc: false },
      'total_b3tr,desc': { id: 'total_b3tr', desc: true },
      'total_b3tr,asc': { id: 'total_b3tr', desc: false },
      'linked_users,desc': { id: 'linked_users', desc: true },
      'linked_users,asc': { id: 'linked_users', desc: false },
    };

    if (sortBy && sortingMap[sortBy]) {
      setSorting([sortingMap[sortBy]]);
    }
  }, [params]);

  const updateM2ELocked = async (
    id: string | undefined,
    value: string | null,
  ) => {
    await apiUpdateM2ELocked(id, value);
    refetchUsers();
    refetchScammers();
  };

  const openConfirmationDialog = (userId: string | undefined) => {
    setModals({
      ...modals,
      remove: {
        open: true,
        userId,
      },
    });
  };

  const openCountLoginDialog = (userId: string | undefined) => {
    setModals({
      ...modals,
      login: {
        open: true,
        userId,
        count_login: 0,
      },
    });
  };

  const closeConfirmationDialog = () => {
    setModals({
      ...modals,
      remove: {
        open: false,
        userId: false,
      },
      login: {
        open: false,
        userId: null,
      },
    });
  };

  const removeUser = async () => {
    await apiDeleteUser(modals.remove.userId);
    closeConfirmationDialog();
    refetchUsers();
    refetchScammers();
  };

  const updateCountLogin = async () => {
    await apiUpdateCountLogin(modals.login.userId, modals.login.count_login);
    closeConfirmationDialog();
  };

  const tabChange = (value: number) => {
    setCurrentTab(value);
    setFlag(true);
  };

  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
    isPlaceholderData: isPlaceholderUsersData,
  } = useQuery({
    queryKey: [
      'users',
      params.get('search') ?? '',
      params.get('pageIndex'),
      params.get('pageSize'),
      params.get('sortBy'),
      network,
      filters,
      currentTab === 0,
    ],
    queryFn: async () => {
      console.log(currentTab);
      if (currentTab === 0) {
        const pageIndex =
          Number(params.get('pageIndex')) || user.pageIndex || 1;
        const pageSize = Number(params.get('pageSize')) || user.pageSize || 10;
        const search = params.get('search') || user.search || '';
        let sortBy = '';
        let sortOrder = '';
        if (sorting.length > 0) {
          sortBy = sorting[0].id;
          sortOrder = sorting[0].desc ? 'desc' : 'asc';
        }
        const sortByParam = params.get('sortBy');
        const sortingMap: {
          [key: string]: { sortBy: string; sortOrder: string };
        } = {
          'total_xp,desc': { sortBy: 'total_xp', sortOrder: 'desc' },
          'total_xp,asc': { sortBy: 'total_xp', sortOrder: 'asc' },
          'cur_xp,desc': { sortBy: 'cur_xp', sortOrder: 'desc' },
          'cur_xp,asc': { sortBy: 'cur_xp', sortOrder: 'asc' },
          'disabled,desc': { sortBy: 'disabled', sortOrder: 'desc' },
          'disabled,asc': { sortBy: 'disabled', sortOrder: 'asc' },
          'wallet_address,desc': {
            sortBy: 'wallet_address',
            sortOrder: 'desc',
          },
          'wallet_address,asc': { sortBy: 'wallet_address', sortOrder: 'asc' },
          'username,desc': { sortBy: 'username', sortOrder: 'desc' },
          'username,asc': { sortBy: 'username', sortOrder: 'asc' },
          '_id,desc': { sortBy: '_id', sortOrder: 'desc' },
          '_id,asc': { sortBy: '_id', sortOrder: 'asc' },
          'telegram_id,desc': { sortBy: 'telegram_id', sortOrder: 'desc' },
          'telegram_id,asc': { sortBy: 'telegram_id', sortOrder: 'asc' },
          'total_b3tr,desc': { sortBy: 'total_b3tr', sortOrder: 'desc' },
          'total_b3tr,asc': { sortBy: 'total_b3tr', sortOrder: 'asc' },
          'linked_users,desc': { sortBy: 'linked_users', sortOrder: 'desc' },
          'linked_users,asc': { sortBy: 'linked_users', sortOrder: 'asc' },
        };

        if (sortByParam && sortingMap[sortByParam]) {
          sortBy = sortingMap[sortByParam].sortBy;
          sortOrder = sortingMap[sortByParam].sortOrder;
        }

        const scammer = false;

        const filter = [];

        if (filters.length > 0) {
          if (filters[0].id === 'disabled') {
            switch (filters[0].value) {
              case 'Active':
                filter.push({
                  field: 'status',
                  value: 'active',
                });
                break;
              case 'Disabled':
                filter.push({
                  field: 'status',
                  value: 'disabled',
                });
                break;
              default:
                break;
            }
          } else {
            filter.push({
              field: filters[0].id,
              value: filters[0].value,
            });
          }
        }

        return await apiGetUsers({
          search,
          pageIndex,
          pageSize,
          sortBy,
          sortOrder,
          filter,
          scammer,
          archived: false,
          network,
        });
      }
    },
    placeholderData: keepPreviousData,
  });

  const {
    data: scammers,
    isLoading: isLoadingScammers,
    refetch: refetchScammers,
    isPlaceholderData: isPlaceholderScammersData,
  } = useQuery({
    queryKey: [
      'scammers',
      params.get('search') ?? '',
      params.get('pageIndex'),
      params.get('pageSize'),
      params.get('sortBy'),
      network,
      scamFilters,
      currentTab === 1,
    ],
    queryFn: async () => {
      if (currentTab === 1) {
        const pageIndex =
          Number(params.get('pageIndex')) || user.pageIndex || 1;
        const pageSize = Number(params.get('pageSize')) || user.pageSize || 10;
        const search = params.get('search') || user.search || '';
        let sortBy = '';
        let sortOrder = '';
        if (sorting.length > 0) {
          sortBy = sorting[0].id;
          sortOrder = sorting[0].desc ? 'desc' : 'asc';
        }
        const scammer = true;

        const filter = [];

        if (scamFilters.length > 0) {
          if (scamFilters[0].id === 'disabled') {
            switch (scamFilters[0].value) {
              case 'Active':
                filter.push({
                  field: 'status',
                  value: 'active',
                });
                break;
              case 'Disabled':
                filter.push({
                  field: 'status',
                  value: 'disabled',
                });
                break;
              default:
                break;
            }
          } else {
            filter.push({
              field: scamFilters[0].id,
              value: scamFilters[0].value,
            });
          }
        }

        return await apiGetUsers({
          search,
          pageIndex,
          pageSize,
          sortBy,
          sortOrder,
          filter,
          scammer,
          archived: false,
          network,
        });
      }
    },
    placeholderData: keepPreviousData,
  });

  const {
    data: archived,
    isLoading: isLoadingArchived,
    // refetch: refetchArchived,
    isPlaceholderData: isPlaceholderArchivedData,
  } = useQuery({
    queryKey: [
      'archived',
      params.get('search') ?? '',
      params.get('pageIndex'),
      params.get('pageSize'),
      params.get('sortBy'),
      network,
      scamFilters,
      currentTab === 2,
    ],
    queryFn: async () => {
      if (currentTab === 2) {
        const pageIndex =
          Number(params.get('pageIndex')) || user.pageIndex || 1;
        const pageSize = Number(params.get('pageSize')) || user.pageSize || 10;
        const search = params.get('search') || user.search || '';
        let sortBy = '';
        let sortOrder = '';
        if (sorting.length > 0) {
          sortBy = sorting[0].id;
          sortOrder = sorting[0].desc ? 'desc' : 'asc';
        }
        const scammer = false;
        const archived = true;

        const filter = [];

        if (scamFilters.length > 0) {
          if (scamFilters[0].id === 'disabled') {
            switch (scamFilters[0].value) {
              case 'Active':
                filter.push({
                  field: 'status',
                  value: 'active',
                });
                break;
              case 'Disabled':
                filter.push({
                  field: 'status',
                  value: 'disabled',
                });
                break;
              default:
                break;
            }
          } else {
            filter.push({
              field: scamFilters[0].id,
              value: scamFilters[0].value,
            });
          }
        }

        return await apiGetUsers({
          search,
          pageIndex,
          pageSize,
          sortBy,
          sortOrder,
          filter,
          scammer,
          archived,
          network,
        });
      }
    },
    placeholderData: keepPreviousData,
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IUserModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 70,
        enableColumnFilter: false,
      }),
      helper.accessor('username', {
        header: 'Name',
        cell: (info) => info.getValue(),
        meta: {
          filterVariant: 'text',
        },
      }),
      helper.accessor('wallet_address', {
        header: 'Wallet Address',
        cell: (info) => {
          const address = info.getValue();
          if (address) {
            return `${address.slice(0, 5)}......${address.slice(-5)}`;
          }
          return '';
        },
        meta: {
          filterVariant: 'text',
        },
      }),
      helper.display({
        header: 'Country',
        cell: (info) => {
          const countriesList = info.row.original.regions?.map((item) =>
            countries.getName(item, 'en'),
          );
          return countriesList?.toString();
        },
        enableColumnFilter: false,
      }),
      helper.accessor('telegram_id', {
        header: 'TG ID',
        cell: (info) => info.getValue(),
        meta: {
          filterVariant: 'text',
        },
      }),
      helper.accessor('level', {
        header: 'Level',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      helper.accessor('linked_users', {
        header: 'Linked Users',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      helper.accessor('total_xp', {
        header: 'Total XP',
        cell: (info) => {
          return (
            <>
              <Box
                onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
                  setTotalXp(info?.row?.original?.total_xp);
                  setL2exp(info?.row?.original?.l2e_xp);
                  setM2exp(info?.row?.original?.m2e_xp);
                  setReferralXp(info?.row?.original?.referral_xp);
                  setAnchorEl(event.currentTarget);
                }}
              >
                {info.getValue()?.toLocaleString()}
              </Box>
            </>
          );
        },
        enableColumnFilter: false,
      }),
      helper.accessor('cur_xp', {
        header: 'Current XP',
        cell: (info) => info.getValue()?.toLocaleString(),
        enableColumnFilter: false,
      }),
      helper.accessor('total_b3tr', {
        header: 'Total B3TR',
        cell: (info) => info.getValue()?.toLocaleString(),
        enableColumnFilter: false,
      }),
      helper.accessor('disabled', {
        header: 'Status',
        cell: (info) => (info.getValue() ? 'Disabled' : 'Active'),
        meta: {
          filterVariant: 'select',
          filterOptions: statusList,
        },
      }),
      helper.display({
        header: 'M2E Lock',
        cell: (info) => (
          <Select
            value={info.row.original.m2e_locked}
            onChange={(e, value) =>
              updateM2ELocked(info.row.original._id, value)
            }
          >
            <Option value={'auto'}>Auto</Option>
            <Option value={'unlocked'}>Unlocked</Option>
            <Option value={'locked'}>locked</Option>
          </Select>
        ),
      }),
      helper.accessor('createdAt', {
        header: 'Created At',
        cell: (info) => {
          if (info.getValue()) {
            return moment(info.getValue()).format('HH:mm:ss - DD/MM/YY');
          } else {
            return null;
          }
        },
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                openCountLoginDialog(info.row.original._id);
              }}
            >
              <Settings size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                navigate(`detail/${info.row.original._id}`);
              }}
            >
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() => {
                openConfirmationDialog(info.row.original._id);
              }}
            >
              <TrashIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [helper]);

  const scammerHelper = useMemo(() => {
    return createColumnHelper<Partial<IUserModel>>();
  }, []);

  const scammersColumns = useMemo(() => {
    return [
      scammerHelper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 70,
        enableColumnFilter: false,
      }),
      scammerHelper.accessor('username', {
        header: 'Name',
        cell: (info) => info.getValue(),
        meta: {
          filterVariant: 'text',
        },
      }),
      scammerHelper.accessor('wallet_address', {
        header: 'Wallet Address',
        cell: (info) => {
          const address = info.getValue();
          if (address) {
            return `${address.slice(0, 5)}......${address.slice(-5)}`;
          }
          return '';
        },
        meta: {
          filterVariant: 'text',
        },
      }),
      scammerHelper.display({
        header: 'Country',
        cell: (info) => {
          const countriesList = info.row.original.regions?.map((item) =>
            countries.getName(item, 'en'),
          );
          return countriesList?.toString();
        },
        enableColumnFilter: false,
      }),
      scammerHelper.accessor('telegram_id', {
        header: 'TG ID',
        cell: (info) => info.getValue(),
        meta: {
          filterVariant: 'text',
        },
      }),
      helper.accessor('level', {
        header: 'Level',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      scammerHelper.accessor('linked_users', {
        header: 'Linked Users',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      scammerHelper.accessor('total_xp', {
        header: 'Total XP',
        cell: (info) => {
          return (
            <>
              <Box
                onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
                  setTotalXp(info?.row?.original?.total_xp);
                  setL2exp(info?.row?.original?.l2e_xp);
                  setM2exp(info?.row?.original?.m2e_xp);
                  setReferralXp(info?.row?.original?.referral_xp);
                  setAnchorEl(event.currentTarget);
                }}
              >
                {info.getValue()?.toLocaleString()}
              </Box>
            </>
          );
        },
        enableColumnFilter: false,
      }),
      scammerHelper.accessor('cur_xp', {
        header: 'Current XP',
        cell: (info) => info.getValue()?.toLocaleString(),
        enableColumnFilter: false,
      }),
      scammerHelper.accessor('total_b3tr', {
        header: 'Total B3TR',
        cell: (info) => info.getValue()?.toLocaleString(),
        enableColumnFilter: false,
      }),
      scammerHelper.accessor('scam_reason', {
        header: 'Scam Reason',
        cell: (info) => info.getValue(),
      }),
      scammerHelper.accessor('disabled', {
        header: 'Status',
        cell: (info) => (info.getValue() ? 'Disabled' : 'Active'),
        meta: {
          filterVariant: 'select',
          filterOptions: statusList,
        },
      }),
      scammerHelper.display({
        header: 'M2E Lock',
        cell: (info) => (
          <Select
            value={info.row.original.m2e_locked}
            onChange={(e, value) =>
              updateM2ELocked(info.row.original._id, value)
            }
          >
            <Option value={'auto'}>Auto</Option>
            <Option value={'unlocked'}>Unlocked</Option>
            <Option value={'locked'}>locked</Option>
          </Select>
        ),
      }),
      scammerHelper.accessor('createdAt', {
        header: 'Created At',
        cell: (info) => {
          if (info.getValue()) {
            return moment(info.getValue()).format('HH:mm:ss - DD/MM/YY');
          } else {
            return null;
          }
        },
      }),
      scammerHelper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                navigate(`detail/${info.row.original._id}`);
              }}
            >
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() => {
                openConfirmationDialog(info.row.original._id);
              }}
            >
              <TrashIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [scammerHelper]);

  const usersTable = useReactTable({
    data: users?.rows ?? [],
    enableFilters: true,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: users?.total ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
      columnFilters: filters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
  });

  const scammersTable = useReactTable({
    data: scammers?.rows ?? [],
    enableFilters: true,
    columns: scammersColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: scammers?.total ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
      columnFilters: scamFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setScamFilters,
  });

  const archivedHelper = useMemo(() => {
    return createColumnHelper<Partial<IUserModel>>();
  }, []);

  const archivedColumns = useMemo(() => {
    return [
      archivedHelper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 70,
        enableColumnFilter: false,
      }),
      archivedHelper.accessor('username', {
        header: 'Name',
        cell: (info) => info.getValue(),
        meta: {
          filterVariant: 'text',
        },
      }),
      archivedHelper.accessor('wallet_address', {
        header: 'Wallet Address',
        cell: (info) => {
          const address = info.getValue();
          if (address) {
            return `${address.slice(0, 5)}......${address.slice(-5)}`;
          }
          return '';
        },
        meta: {
          filterVariant: 'text',
        },
      }),
      archivedHelper.display({
        header: 'Country',
        cell: (info) => {
          const countriesList = info.row.original.regions?.map((item) =>
            countries.getName(item, 'en'),
          );
          return countriesList?.toString();
        },
        enableColumnFilter: false,
      }),
      archivedHelper.accessor('telegram_id', {
        header: 'TG ID',
        cell: (info) => info.getValue(),
        meta: {
          filterVariant: 'text',
        },
      }),
      helper.accessor('level', {
        header: 'Level',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      archivedHelper.accessor('linked_users', {
        header: 'Linked Users',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      archivedHelper.accessor('total_xp', {
        header: 'Total XP',
        cell: (info) => {
          return (
            <>
              <Box
                onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
                  setTotalXp(info?.row?.original?.total_xp);
                  setL2exp(info?.row?.original?.l2e_xp);
                  setM2exp(info?.row?.original?.m2e_xp);
                  setReferralXp(info?.row?.original?.referral_xp);
                  setAnchorEl(event.currentTarget);
                }}
              >
                {info.getValue()?.toLocaleString()}
              </Box>
            </>
          );
        },
        enableColumnFilter: false,
      }),
      archivedHelper.accessor('cur_xp', {
        header: 'Current XP',
        cell: (info) => info.getValue()?.toLocaleString(),
        enableColumnFilter: false,
      }),
      archivedHelper.accessor('total_b3tr', {
        header: 'Total B3TR',
        cell: (info) => info.getValue()?.toLocaleString(),
        enableColumnFilter: false,
      }),
      archivedHelper.accessor('disabled', {
        header: 'Status',
        cell: (info) => (info.getValue() ? 'Disabled' : 'Active'),
        meta: {
          filterVariant: 'select',
          filterOptions: statusList,
        },
      }),
      archivedHelper.display({
        header: 'M2E Lock',
        cell: (info) => (
          <Select
            value={info.row.original.m2e_locked}
            onChange={(e, value) =>
              updateM2ELocked(info.row.original._id, value)
            }
          >
            <Option value={'auto'}>Auto</Option>
            <Option value={'unlocked'}>Unlocked</Option>
            <Option value={'locked'}>locked</Option>
          </Select>
        ),
      }),
      archivedHelper.accessor('createdAt', {
        header: 'Created At',
        cell: (info) => {
          if (info.getValue()) {
            return moment(info.getValue()).format('HH:mm:ss - DD/MM/YY');
          } else {
            return null;
          }
        },
      }),
      archivedHelper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                openCountLoginDialog(info.row.original._id);
              }}
            >
              <Settings size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                navigate(`detail/${info.row.original._id}`);
              }}
            >
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() => {
                openConfirmationDialog(info.row.original._id);
              }}
            >
              <TrashIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [archivedHelper]);

  const archivedTable = useReactTable({
    data: archived?.rows ?? [],
    enableFilters: true,
    columns: archivedColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: archived?.total ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
      columnFilters: filters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
  });

  useEffect(() => {
    setSearch('');
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.delete('sortBy');
      newParams.delete('search');
      newParams.delete('pageIndex');
      newParams.delete('pageSize');
      newParams.delete('type');
      newParams.set('pageIndex', '1');
      newParams.set('pageSize', pagination.pageSize.toString());
      if (currentTab === 0) {
        newParams.set('type', 'users');
      } else if (currentTab === 1) {
        newParams.set('type', 'scammers');
      } else {
        newParams.set('type', 'archived');
      }
      return newParams;
    });
    if (flag) {
      setPagination({
        ...pagination,
        pageIndex: 0,
      });
    }
  }, [currentTab]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      if (sorting.length === 0) {
        newParams.delete('sortBy');
      } else {
        newParams.set(
          'sortBy',
          sorting.map((s) => `${s.id},${s.desc ? 'desc' : 'asc'}`).join(','),
        );
      }
      return newParams;
    });
  }, [sorting]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      if (search) {
        newParams.set('search', search);
      } else {
        newParams.delete('search');
      }
      return newParams;
    });
    if (search !== '' && search !== null) {
      setPagination({
        ...pagination,
        pageIndex: 0,
      });
    }
  }, [search]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.set('pageIndex', (pagination.pageIndex + 1).toString());
      newParams.set('pageSize', pagination.pageSize.toString());
      return newParams;
    });
  }, [pagination]);

  useEffect(() => {
    if (params.get('type') === 'users') {
      setCurrentTab(0);
    } else if (params.get('type') === 'scammers') {
      setCurrentTab(1);
    } else {
      setParams((oldParams) => {
        const newParams = new URLSearchParams(oldParams);
        newParams.set('type', 'users');
        return newParams;
      });
    }
    setPagination({
      pageIndex: Number(params.get('pageIndex'))
        ? Number(params.get('pageIndex')) - 1
        : 0,
      pageSize: Number(params.get('pageSize')) || 10,
    });
  }, []);

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title={
                currentTab === 0
                  ? 'Total Users'
                  : currentTab === 1
                    ? 'Scammers'
                    : 'Archived'
              }
              value={
                currentTab === 0
                  ? users?.total ?? 0
                  : currentTab === 1
                    ? scammers?.total ?? 0
                    : archived?.total ?? 0
              }
              variant="dark"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack mt={3}>
        <Tabs onTabChangeHandler={tabChange} tab={currentTab}>
          <TabHeader>
            <TabHeaderItem>Users</TabHeaderItem>
            <TabHeaderItem>Scammers</TabHeaderItem>
            <TabHeaderItem>Archived</TabHeaderItem>
          </TabHeader>
          <TabBody>
            <>
              <Box mt={2}>
                <Input
                  placeholder="Search Input"
                  value={search}
                  sx={{ width: '300px' }}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Box mt={2}>
                <DataTable
                  key={1}
                  table={usersTable}
                  loading={isLoadingUsers || isPlaceholderUsersData}
                />
              </Box>
            </>
            <>
              <Box mt={2}>
                <Input
                  placeholder="Search Input"
                  value={search}
                  sx={{ width: '300px' }}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Box mt={2}>
                <DataTable
                  key={2}
                  table={scammersTable}
                  loading={isLoadingScammers || isPlaceholderScammersData}
                />
              </Box>
            </>
            <>
              <Box mt={2}>
                <Input
                  placeholder="Search Input"
                  value={search}
                  sx={{ width: '300px' }}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Box mt={2}>
                <DataTable
                  key={3}
                  table={archivedTable}
                  loading={isLoadingArchived || isPlaceholderArchivedData}
                />
              </Box>
            </>
          </TabBody>
        </Tabs>
      </Stack>

      <ConfirmationDialog
        open={modals.remove.open}
        title="Remove User"
        description="Are you sure to remove current user?"
        onPositive={removeUser}
        onNegative={closeConfirmationDialog}
      />

      <ConfirmationDialog
        open={modals.login.open}
        title="Login Days"
        description={
          <Input
            type="number"
            onChange={(e) =>
              setModals({
                ...modals,
                login: {
                  ...modals.login,
                  count_login: Number(e.target.value),
                },
              })
            }
          />
        }
        onPositive={updateCountLogin}
        onNegative={closeConfirmationDialog}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          padding={2}
          onMouseLeave={() => {
            setAnchorEl(null);
          }}
        >
          <Typography>Total_xp: {totalXp?.toLocaleString()}</Typography>
          <Typography>L2e_xp: {l2e_xp?.toLocaleString()}</Typography>
          <Typography>M2e_xp: {m2e_xp?.toLocaleString()}</Typography>
          <Typography>Referral_xp: {referralXp?.toLocaleString()}</Typography>
        </Box>
      </Popover>
    </>
  );
};

export default UsersPage;
